
import { computed, defineComponent, PropType } from 'vue'
import Chip from 'primevue/chip'
import {
  Svg,
  useUserNameOrPlaceholder,
  getUserRoleLabel,
  useUserType,
  useUserTypeClass,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { ClientDetailsDto } from '@bd/api'
import { EmployeeDetailsDto } from '@bd/api/admin-api/types'

export default defineComponent({
  name: 'UserDetailsCard',
  components: { Chip, Svg },
  props: {
    user: {
      type: Object as PropType<ClientDetailsDto & EmployeeDetailsDto>,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    displayOptions: {
      type: Array as PropType<('description' | 'id')[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n()
    const display = computed(() => {
      return {
        description: props.displayOptions.includes('description'),
        id: props.displayOptions.includes('id'),
      }
    })
    const user = computed(() => props.user)
    const userType = useUserType(user)
    const userTypeClass = useUserTypeClass(userType)

    const userRoles = computed(() => {
      return user.value.roles.map((role) => ({
        value: role,
        label: getUserRoleLabel(role, user.value.sellerDeveloper),
      }))
    })

    const computedClass = computed(() => {
      return `user-type-${userTypeClass.value}`
    })

    const userName = useUserNameOrPlaceholder(user)

    return {
      t,
      display,
      computedClass,
      userName,
      userRoles,
    }
  },
})
