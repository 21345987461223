<template>
  <div
    class="user-details-card d-flex flex-column justify-content-between"
    :class="computedClass"
  >
    <div class="user-details-row">
      <div class="user-details-column">
        <span class="caption" v-if="display.id">
          {{ user.displayableId ?? user.userId }}
        </span>
        <span class="h-1 mb-2 text-break-word">
          {{ userName }}
        </span>
        <span class="body-sm text-wrap-5" v-if="display.description">{{
          description
        }}</span>
      </div>
      <div class="user-details-column user-roles">
        <Chip
          v-for="role of userRoles"
          :key="role.value"
          :label="role.label"
          class="mb-2 text-uppercase"
        />
      </div>
    </div>
    <div class="user-details-row">
      <div class="user-details-column mt-3">
        <div class="d-flex align-items-center">
          <Svg :src="require('@bd/assets/icons/mail.svg')" class="mr-2 icon" />
          <span class="body-sm">{{ user.email ?? t('no_information') }}</span>
        </div>
        <div class="d-flex align-items-center">
          <Svg :src="require('@bd/assets/icons/phone.svg')" class="mr-2 icon" />
          <span class="body-sm">{{
            user.phoneNumber ?? t('no_information')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Chip from 'primevue/chip'
import {
  Svg,
  useUserNameOrPlaceholder,
  getUserRoleLabel,
  useUserType,
  useUserTypeClass,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { ClientDetailsDto } from '@bd/api'
import { EmployeeDetailsDto } from '@bd/api/admin-api/types'

export default defineComponent({
  name: 'UserDetailsCard',
  components: { Chip, Svg },
  props: {
    user: {
      type: Object as PropType<ClientDetailsDto & EmployeeDetailsDto>,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    displayOptions: {
      type: Array as PropType<('description' | 'id')[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n()
    const display = computed(() => {
      return {
        description: props.displayOptions.includes('description'),
        id: props.displayOptions.includes('id'),
      }
    })
    const user = computed(() => props.user)
    const userType = useUserType(user)
    const userTypeClass = useUserTypeClass(userType)

    const userRoles = computed(() => {
      return user.value.roles.map((role) => ({
        value: role,
        label: getUserRoleLabel(role, user.value.sellerDeveloper),
      }))
    })

    const computedClass = computed(() => {
      return `user-type-${userTypeClass.value}`
    })

    const userName = useUserNameOrPlaceholder(user)

    return {
      t,
      display,
      computedClass,
      userName,
      userRoles,
    }
  },
})
</script>

<style lang="scss" scoped>
.user-details-card {
  padding: 40px 35px;
  border-radius: 6px;
  box-shadow: $default-shadow;
  background-color: $white;
  min-height: 18.75rem;
  max-width: 40vw;
}

.user-details-row {
  @include flex($justify: space-between, $align: flex-start);
}

.user-details-column {
  @include flex($direction: column, $align: flex-start);
  &.user-roles {
    margin-left: 1rem;
  }
}

.p-chip {
  min-width: 140px;
  height: 28px;
  justify-content: center;
  font-weight: 500;
}

.icon {
  width: 14px;
  height: 14px;
}

.user-type {
  &-seller {
    color: $dodger-blue;

    .p-chip {
      color: $dodger-blue;
      background-color: rgba($dodger-blue, 0.1);
    }
  }
  &-buyer {
    color: $zest;

    .p-chip {
      color: $zest;
      background-color: rgba($zest, 0.1);
    }
  }
  &-agent,
  &-admin,
  &-callcenter {
    color: $cerulean;

    .p-chip {
      color: $cerulean;
      background-color: $cerulean-light;
    }
  }
  &-developer {
    color: $jungle-green;

    .p-chip {
      color: $jungle-green;
      background-color: rgba($jungle-green, 0.1);
    }
  }
}
</style>
