
import { computed, defineComponent, ref } from 'vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useI18n } from 'vue-i18n'
import { ConfirmDialog, UserAvatar, UserType } from '@bd/components'
import UserDetailsCard from '@bd/admin/components/Users/UserDetailsCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '../../store'
import { EmployeeEvent } from '@bd/admin/config'
import { useToast } from 'primevue/usetoast'
import { EmployeeEventMessages } from '@bd/admin/config/toast'
import { EmployeeConfirmDialogState } from '@bd/admin/types'

export default defineComponent({
  name: 'Calendar',
  components: { AppContentLayout, UserAvatar, UserDetailsCard, ConfirmDialog },
  setup() {
    const i18n = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const employeeDetails = computed(
      () => store.state.employees?.employeeDetails,
    )

    const fetchEmployeeDetails = async () => {
      await store.dispatch('employees/employee', route.params.id)
    }

    const confirmDialogState = ref<EmployeeConfirmDialogState>({
      visible: false,
      title: '',
      submitPending: false,
    })

    const closeConfirmationDialog = () => {
      confirmDialogState.value.visible = false
    }

    const performConfirmationRequiredAction = async (
      action: () => Promise<void>,
      errorHandler: (err: unknown) => void,
      useDefaultSuccessHandler = true,
    ) => {
      confirmDialogState.value.submitPending = true
      try {
        await action()
        if (useDefaultSuccessHandler) {
          closeConfirmationDialog()
        }
      } catch (err) {
        errorHandler(err)
      } finally {
        confirmDialogState.value.submitPending = false
      }
    }

    const openConfirmDialog = async (actionType: EmployeeEvent) => {
      confirmDialogState.value = {
        actionType: actionType,
        title: i18n.t(`confirmDialog.employees.${actionType}.title`),
        visible: true,
        submitPending: false,
      }
    }

    const removeEmployee = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('employees/removeEmployee', route.params.id)
          closeConfirmationDialog()
          await router.push({ name: 'Employees' })
          toast.add(EmployeeEventMessages.removeSuccess)
        },
        () => {
          toast.add(EmployeeEventMessages.removeError)
        },
        false,
      )
    }

    const blockEmployee = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('employees/blockEmployee', route.params.id)
          toast.add(EmployeeEventMessages.blockSuccess)
        },
        () => {
          toast.add(EmployeeEventMessages.blockError)
        },
      )
    }

    const unblockEmployee = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('employees/unblockEmployee', route.params.id)
          toast.add(EmployeeEventMessages.unblockSuccess)
        },
        () => {
          toast.add(EmployeeEventMessages.unblockError)
        },
      )
    }

    const onDialogConfirm = () => {
      const actionType = confirmDialogState.value.actionType
      if (actionType === EmployeeEvent.REMOVE) {
        removeEmployee()
      } else if (actionType === EmployeeEvent.BLOCK) {
        blockEmployee()
      } else if (actionType === EmployeeEvent.UNBLOCK) {
        unblockEmployee()
      }
    }

    const onDialogReject = () => {
      confirmDialogState.value.visible = false
    }

    fetchEmployeeDetails()

    return {
      ...i18n,
      EmployeeEvent,
      employeeDetails,
      confirmDialogState,
      openConfirmDialog,
      onDialogConfirm,
      onDialogReject,
      UserType,
    }
  },
})
